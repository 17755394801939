import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0',
        component_token: 'component_token', // embed_token -> request
        token: 'token',
        token_valid_until: '',
        debugMode: 'false',
        debugMethod: 'clidetrswxz',
        APILocation: 'dev'
    },
    getters: {
        appVersion: state => {
            return state.packageVersion;
        },
        component_token: state => {
            return state.component_token;
        },
        appToken: state => {
            return state.token;
        },
        token_valid_until: state => {
            return state.token_valid_until;
        },
        debugMode: state => {
            return state.debugMode;
        },
        debugMethod: state => {
            return state.debugMethod;
        },
        APILocation: state => {
            return state.APILocation;
        }
    },
    mutations: {
        // SET_TOKEN: (state, payload) => {
        //     state.token = newValue
        // }
    },
    actions: {
        // setToken: ({ commit, state }, payload) => {
        //     commit('SET_TOKEN', newValue)
        //     return state.token
        // }
    }
});
