import { IRdwVehicle } from '@/models/RdwVehicle';
import { debug } from '@/services/Debugger';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

/**
 * LicensePlate Service
 *
 * LicensePlate Service handles rest calls from axios to get LicensePlate info
 */
class LicensPlateService {
    http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            headers: {
                'X-App-Token': 'y0lu5Ouiv8AraV06JZdoQnW48'
            }
        });
    }

    public setProperLicensePlate(licPlate: string) {
        var retVal = licPlate;
        if (licPlate) {
            licPlate = licPlate.trim();
            retVal = licPlate;
            var re = /-/g;
            if (licPlate.length == 6 && ((licPlate || '').match(re) || []).length === 0) {
                var currentChar = '';
                var validChar = '';
                licPlate = licPlate.toUpperCase();
                for (var i = 0; i < licPlate.length; i++) {
                    currentChar = licPlate.substr(i, 1);
                    //A-Z
                    if ((currentChar.charAt(0).match(/[0-9_]/) == null) == true) {
                        validChar = validChar + 'X';
                    } else {
                        //0-9
                        validChar = validChar + '9';
                    }
                }
                if (validChar) {
                    switch (validChar) {
                        case '99XXX9':
                        case 'XX999X':
                            retVal = licPlate.substr(0, 2) + '-' + licPlate.substr(2, 3) + '-' + licPlate.substr(5, 1);
                            break;
                        case '9XXX99':
                        case 'X999XX':
                            retVal = licPlate.substr(0, 1) + '-' + licPlate.substr(1, 3) + '-' + licPlate.substr(4, 2);
                            break;
                        case 'XXX99X':
                        case '999XX9':
                            retVal = licPlate.substr(0, 3) + '-' + licPlate.substr(3, 2) + '-' + licPlate.substr(5, 1);
                            break;
                        case 'X99XXX':
                        case '9XX999':
                            retVal = licPlate.substr(0, 1) + '-' + licPlate.substr(1, 2) + '-' + licPlate.substr(3, 3);
                            break;
                        default:
                            retVal = licPlate.substr(0, 2) + '-' + licPlate.substr(2, 2) + '-' + licPlate.substr(4, 2);
                            break;
                    }
                }
            }
        }
        if (retVal) {
            retVal = retVal.toUpperCase();
        }
        return retVal;
    }

    public async getRDWData(kenteken: string): Promise<AxiosResponse> {
        return this.http.get(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${kenteken}`);
    }

    public getBasicData(rdwJson: IRdwVehicle) {
        let vehicleData = '';
        let brand_type = '';
        let tradeName: string = '';
        let apkExpirationDate: string = '';
        let brand: string = '';

        if (rdwJson.merk) {
            brand = rdwJson.merk;
            vehicleData += 'Merk: ' + brand + ', ';
        }
        if (rdwJson.handelsbenaming) {
            tradeName = rdwJson.handelsbenaming;
            vehicleData += 'Type: ' + tradeName + ', ';
        }
        if (rdwJson.vervaldatum_apk) {
            apkExpirationDate = rdwJson.vervaldatum_apk;
        }
        brand_type = brand + ' ' + tradeName;

        return { vehicleData: vehicleData, brand_type: brand_type, vervaldatum_apk: apkExpirationDate };
    }
}

export const licensePlateService = new LicensPlateService();
