import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
    LayoutPlugin,
    FormPlugin,
    CalendarPlugin,
    SpinnerPlugin,
    FormInputPlugin,
    FormGroupPlugin,
    JumbotronPlugin,
    ModalPlugin,
    FormCheckboxPlugin,
    ButtonGroupPlugin,
    ButtonPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    FormRadioPlugin,
    InputGroupPlugin,
    BadgePlugin
} from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import vueCustomElement from 'vue-custom-element';
import VueGtag from 'vue-gtag';

// @ts-ignore
import vueNumeralFilterInstaller from 'vue-numeral-filter';

// Import Bootstrap an BootstrapVue CSS files (order is important)

// store the app in a variable to prevent typescript from giving errors
const VueApp: any = App;

Vue.config.productionTip = false;

Vue.use(PortalVue);
//import Bootstrap plugins -> components
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(CalendarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(ModalPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormRadioPlugin);
Vue.use(InputGroupPlugin);
Vue.use(BadgePlugin);

Vue.use(vueNumeralFilterInstaller, { locale: 'nl-NL' });
Vue.use(vueCustomElement);

//Init analytics
Vue.use(VueGtag, {
    config: {
        id: 'G-L45XJXT323',
        params: {
            send_page_view: false
        }
    }
});

// register it as a custom element
VueApp.store = store;
router();
Vue.customElement('oa-widget', VueApp);
