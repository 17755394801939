<template>
    <div id="autoflex-oa-app">
        <router-view :settings="settings"></router-view>
        <div class="version">Powered by Autoflex | {{ this.$store.getters.appVersion }}</div>
    </div>
</template>

<script>
export default {
    props: ['settings']
}
</script>

<style lang="scss">
    #autoflex-oa-app {
        @import '~@/styles/oa.scss';
    }
    @import '~@/styles/modal.scss';
</style>