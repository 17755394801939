import Vue from 'vue';
import VueRouter from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Home from './views/Home.vue';

let initialized = false;

export default function() {
    if (initialized) {
        return;
    }

    Vue.use(VueRouter);

    const routes = [
        {
            path: '*',
            name: 'home',
            component: Home
        }
    ];

    const router = new VueRouter({ routes, mode: 'history' });

    Vue.mixin({ router });
    initialized = true;

    Sentry.init({
        Vue,
        dsn: 'https://2d6f0db29ba04d5fbe0b68f9f624e4c0@o4504723287310336.ingest.sentry.io/4504723294978048',
        tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.25 : 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 0.25 : 1.0,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [/.*[.](autoflex|af)([0-9]+)?[.].*/, /^\//]
            }),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true
            })
        ],
        enabled: false // Disabled for now, until we wrap OA into an iframe
    });
}
