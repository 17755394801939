import { IAuthenticationComponent } from '@/models/AuthenticationComponent';
import { debug } from '@/services/Debugger';
import { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import store from '@/store';
import axios from 'axios';

export default class ApiService {
    http: AxiosInstance;
    // set API url, for local, vip, dev or prod, should be default nl
    serverPath: string;

    constructor() {
        this.http = axios.create({});
        // Request interceptor for API calls
        this.http.interceptors.request.use(
            async config => {
                const token = store.getters.appToken;
                if (token) {
                    config.headers.token = token;
                }
                return config;
            },
            error => {
                Promise.reject(error);
            }
        );

        // Response interceptor for API calls
        this.http.interceptors.response.use(
            response => {
                return response;
            },
            async error => {
                const originalRequest: AxiosRequestConfig = error.config;
                if (error.response.status === 401) {
                    debug.warn('retry config');
                    debug.response('originalRequest: ', originalRequest);
                    await this.getSessionToken();
                    originalRequest.headers.token = store.state.token;
                    return axios(originalRequest);
                }
                return error.response;
            }
        );

        // !@# TODO store is not yet set here
        debug.info('setAPIurl');
        debug.log('APILocation', store.getters.APILocation);
        // alleen uitzondering voor local en dev
        this.serverPath = 'https://www.autoflex10.' + store.getters.APILocation + '/autoflex/servoy-service/velocity/webservice_v2/';
        if (store.getters.APILocation === 'local-local') {
            this.serverPath = 'http://localhost:8080/servoy-service/velocity/webservice_v2/';
        } else if (store.getters.APILocation === 'local-dev') {
            this.serverPath = 'https://www.autoflex10.dev/autoflex/servoy-service/velocity/webservice_v2/';
        }
        debug.log('serverPath', this.serverPath);
    }

    public async isServerAccessible(): Promise<boolean> {
        return this.getApiData('', 'util/healthy')
            .then(result => {
                if (result.status === 200) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                return false;
            });
    }

    /**
     * @author MdeB
     * @since 18-02-2012
     *
     * @description Check if supplied token exists on server
     *
     * @example getSessionToken()
     * @returns boolean (true / false)
     */
    public async getSessionToken(): Promise<any> {
        debug.info('ApiService / getSessionToken');
        const params = { component_token_id: store.getters.component_token };
        const result: any = await this.getApiData('', 'authenticate/component', '', params);
        let tokenResult: IAuthenticationComponent = result;
        store.state.token = tokenResult.token;
        return result;
    }

    /**
     * @author MdeB
     * @since 18-02-2012
     *
     * @description Get Data from API
     * @summary Get Data from API with possibility to add parameters and id, supply endpoint
     *
     * @example
     *
     * @param path
     * @param endpoint
     * @param id
     * @param params
     */
    public async getApiData(path?: string, endpoint?: string, id?: string, params?: any) {
        endpoint ? endpoint : '';
        id ? (endpoint += '/' + id) : '';
        params ? params : {};
        return this.http
            .get<any>(path ? path : this.serverPath + endpoint, {
                params,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { arrayFormat: 'brackets' });
                }
            })
            .then(response => {
                return response;
            });
    }

    /**
     * @author MdeB
     * @since 18-02-2012
     *
     * @description Post Data to API
     * @summary Post Data to API with possibility to add parameters, data and id, supply endpoint
     *
     * @example
     *
     * @param path
     * @param endpoint
     * @param id
     * @param params
     * @param data
     */
    public async postApiData(path?: string, endpoint?: string, id?: string, params?: any, data?: any): Promise<AxiosResponse> {
        endpoint ? endpoint : '';
        id ? (endpoint += '/' + id) : '';
        params ? params : {};
        data ? data : {};
        return this.http.post<any[]>(path ? path : this.serverPath + endpoint, data, {
            params
        });
    }
}
