











































































































































































































































































































































































































import Vue from 'vue';
import { DateTime } from 'luxon';
import Component from 'vue-class-component';
import { debug } from '@/services/Debugger';
import { IAuthenticationComponent } from '@/models/AuthenticationComponent';
import { IAppointmentConfig } from '@/models/AppointmentConfig';
import { IAddress } from '@/models/Address';
import { ISettings } from '@/models/Settings';
import { ISchedule } from '@/models/Schedule';
import { IFlatrate } from '@/models/Flatrate';
import { IRentGroup } from '@/models/RentGroup';
import { IContactAddress } from '@/models/ContactAddress';
import { IContactClient } from '@/models/ContactClient';
import { IVehicle } from '@/models/Vehicle';
import { IRdwVehicle } from '@/models/RdwVehicle';
import { IEmailBody } from '@/models/EmailBody';
import { licensePlateService } from '@/services/LicensePlateService';
import ApiService from '@/services/ApiService';
import { BcCalendarCtxObject } from 'bootstrap-vue';

Vue.directive('uppercase', {
    update(el: any) {
        const sourceValue = el.value;
        const newValue = sourceValue.toUpperCase();

        if (sourceValue !== newValue) {
            el.value = newValue;
            el.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
});
Vue.directive('no-whitespace', {
    update(el: any) {
        const sourceValue = el.value;
        const re = /\s*/g;
        const newValue = sourceValue.replace(re, '');
        if (sourceValue !== newValue) {
            el.value = newValue;
            el.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
});

@Component
export default class Home extends Vue {
    ApiService: ApiService;
    // init some fields
    isFetching: boolean = true;
    isError: boolean = false;
    isSending: boolean = false;
    isSendingSuccess: boolean = false;
    APIerrorMessage: string = '';
    loadingMessage: string = '';
    message: string = '';
    wizardStep: number = 0;
    wizardTop: boolean = false;
    wizardBottom: boolean = false;
    // do the settings
    settingsAttr: ISettings = {} as ISettings;
    settings: ISettings = {} as ISettings;
    // mail settings to client
    sendEmailBody: IEmailBody = {} as IEmailBody;
    sentEmailResponseData: IEmailBody = {} as IEmailBody;
    // mail settings to helpdesk onError
    mailtoSubject: string = 'AF - Online afspraken: Fout met ophalen data';
    mailtoBody: string = 'AF - Online afspraken: Fout met ophalen data. Server: ' + window.location;

    now = DateTime.now();
    yesterday = DateTime.now().minus({ days: 1 });
    today = DateTime.now().toString();
    tomorrow = DateTime.now().plus({ days: 1 });
    thisYear = DateTime.now().year;
    thisMonth = DateTime.now().month;
    maxDate = DateTime.now()
        .set({ day: DateTime.now().plus({ months: 2 }).daysInMonth })
        .plus({ months: 2 })
        .toString();
    private disabledDates: Array<Date> = [];
    private enabledDates: Array<Date> = [];
    private enabledHours: Array<Number> = [];
    context = {} as BcCalendarCtxObject;
    private calendarSelected = null;
    private minimumAvailable: number = 4;
    private mediorAvailable: number = 5;

    async created() {
        debug.clear();
        // set default settings JSON
        this.settings = {
            token: '',
            wizard: { active: false, navigation: 'top' },
            calendarStartDay: '0',
            testMode: { active: false, text: 'Momenteel werken we aan deze website' },
            debugMode: { active: false, text: 'LET OP! DEVELOPER MODE', method: 'clidetrskwxz' },
            sendMail: false,
            showMailResponse: false,
            APILocation: 'eu'
        };
        // get JSON from attributes
        this.settingsAttr = this.$attrs.settings ? (JSON.parse(this.$attrs.settings) as ISettings) : ({} as ISettings);
        // merge default set with override from attributes
        this.settings = Object.assign(this.settings, this.settingsAttr);

        // TODO use store mutations.... for non api calls. For api calls use actions never edit the store itself
        this.$store.state.component_token = this.settings.token;
        this.$store.state.debugMode = this.settings.debugMode?.active;
        this.$store.state.debugMethod = this.settings.debugMode?.method;
        this.$store.state.APILocation = this.settings.APILocation;

        debug.header('---- AUTOFLEX - ONLINE AFSPRAKEN - DEBUGMODE ----');
        debug.log(this.settings);

        this.ApiService = new ApiService();
        if (!(await this.ApiService.isServerAccessible())) {
            this.isError = true;
            this.isFetching = false;
            this.APIerrorMessage = 'Er kon geen verbinding worden gemaakt met de server probeer het later nog eens';
            return;
        }
    }
    mounted() {
        if (!this.settings.token) {
            //TODO add these setting to the store
            this.isError = true;
            this.isFetching = false;
            this.APIerrorMessage = 'Token missing check settings';
            return;
        }
        this.createOA();
    }

    /**
     * createOA()
     * actually create the Online Afspraken code / page
     */
    createOA() {
        this.$gtag.event('OA loaded', { component_token: this.settings.token });
        this.getSessionToken().then(session => {
            if (session) {
                this.setWizard();

                // Set the modal window to the body to make it part of the top-level html
                var myBody = document.body;
                var myWidget = document.getElementById('submitModal___BV_modal_outer_') as HTMLElement;
                if (myBody) {
                    myBody.appendChild(myWidget);
                }

                // @Mdb v1.0.9. add fix for end of year (november) to also get the right months in the next year
                var addMonth2 = 0;
                var addMonth3 = 0;
                if (DateTime.now().month === 11) {
                    addMonth2 = 0;
                    addMonth3 = 1;
                } else if (DateTime.now().month === 12) {
                    addMonth2 = 1;
                    addMonth3 = 1;
                }

                const requests = [
                    this.getAppointmentConfig(),
                    this.getFlatrates(),
                    this.getRentGroups(),
                    this.getScheduleAvailibility({ year: DateTime.now().year, month: DateTime.now().month }),
                    this.getScheduleAvailibility({ year: DateTime.now().plus({ year: addMonth2 }).year, month: DateTime.now().plus({ months: 1 }).month })
                ];
                Promise.all(requests)
                    .then(() => {
                        //TODO do something if 1 of the 2 function doesnt return true;
                        this.isFetching = false;
                    })
                    .catch(e => {
                        //TODO better error handling
                        console.log(e);
                    });
                //now get the 3th month also but we don't care it is here to be loaded later.
                this.getScheduleAvailibility({ year: DateTime.now().plus({ year: addMonth3 }).year, month: DateTime.now().plus({ months: 2 }).month });
            }
        });
    }

    // #### CALENDAR STUFF
    dateDisabled(ymd: string, date: Date) {
        let idx = this.disabledDates.map(Number).indexOf(+date);
        debug.kalendar(+date, date, idx);
        const day = date.getDate();
        if (idx < 0) {
            return false;
        } else {
            return day;
        }
    }

    /**
     * dateClass(ymd: string, date: Date)
     * set classes of dates based on hours
     */
    private dateClass(ymd: string, date: Date) {
        let idx = this.enabledDates.map(Number).indexOf(+date);
        debug.kalendar(+date, date, idx);
        const day = date.getDate();
        if (idx < 0) {
            return '';
        } else {
            debug.kalendar(idx, this.enabledHours[idx]);
            if (this.enabledHours[idx] > this.mediorAvailable) {
                return 'table-success';
            } else if (this.enabledHours[idx] > this.minimumAvailable && this.enabledHours[idx] <= this.mediorAvailable) {
                return 'table-warning';
            } else if (this.enabledHours[idx] <= this.mediorAvailable) {
                return 'table-danger';
            } else {
                return '';
            }
        }
    }

    /**
     * onContext(ctx)
     * calendar update / set date
     */
    onContext(ctx: BcCalendarCtxObject) {
        this.context = ctx;
        if (this.context.activeDate) {
            this.thisYear = this.context.activeDate.getFullYear();
            this.thisMonth = this.context.activeDate.getMonth();
        }
        debug.kalendar('ctx: ', this.context);
    }

    // #### FORM STUFF
    private workOptions: Array<{ text: string; value: string }> = [];
    private replaceOptions: Array<{ text: string; value: string }> = [];
    private oaFields = {
        show: true,
        preferenceSelected: 0,
        preferenceOptions: [
            { value: 0, text: 'maak een keuze', selected: true, disabled: true },
            { value: 8, text: '08:00' },
            { value: 9, text: '09:00' },
            { value: 10, text: '10:00' },
            { value: 11, text: '11:00' },
            { value: 12, text: '12:00' },
            { value: 13, text: '13:00' },
            { value: 14, text: '14:00' },
            { value: 15, text: '15:00' },
            { value: 16, text: '16:00' },
            { value: 17, text: '17:00' }
        ],
        workSelected: [],
        overige: '',
        priceSelected: [],
        vervoerSelected: 1,
        replaceSelected: '',
        hasReplaceOptions: false,
        zakelijkSelected: 0,
        kenteken: '',
        tellerstand: '',
        voornaam: '',
        tussenvoegsel: '',
        achternaam: '',
        email: '',
        telefoon: '',
        postcode: '',
        huisnummer: '',
        huisnummertoevoeging: '',
        adres: '',
        plaats: '',
        contact_id: '',
        vehicle_id: ''
    };

    // Form State functions
    get tellerState() {
        const re = /^[0-9]*$/;
        let returnValue: any = re.test(this.oaFields.tellerstand);
        if (this.oaFields.tellerstand === '') {
            returnValue = null;
        }
        return returnValue;
    }

    get voornaamState() {
        if (this.oaFields.voornaam === '') {
            return null;
        }
        if (this.oaFields.voornaam.length > 2) {
            return true;
        } else if (this.oaFields.voornaam.length >= 1) {
            return false;
        }
    }

    get achternaamState() {
        if (this.oaFields.achternaam === '') {
            return null;
        }
        return this.oaFields.achternaam.length > 2;
    }

    get werkzaamhedenState() {
        let returnValue = null;
        if (this.oaFields.workSelected.length > 0 && this.oaFields.overige === '') {
            returnValue = true;
        } else if (this.oaFields.workSelected.length === 0 && this.oaFields.overige !== '') {
            returnValue = true;
        } else if (this.oaFields.workSelected.length > 0 && this.oaFields.overige !== '') {
            returnValue = true;
        }
        return returnValue;
    }

    get emailState() {
        if (this.oaFields.email === '') {
            return null;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.oaFields.email);
    }

    get phoneState() {
        if (this.oaFields.telefoon === '') {
            return null;
        }
        const re = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
        return re.test(this.oaFields.telefoon);
    }

    get postcodeState() {
        const re = /^$|^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        let returnValue: any = re.test(this.oaFields.postcode);
        if (this.oaFields.postcode === '') {
            this.oaFields.huisnummer = '';
            this.oaFields.huisnummertoevoeging = '';
            returnValue = null;
        }
        return returnValue;
    }

    get huisnummerState() {
        const re = /^\d{1,8}$/;
        let returnValue = null;
        if (this.oaFields.postcode === '' && this.oaFields.huisnummer === '') {
            returnValue = null;
        } else if (this.postcodeState && this.oaFields.huisnummer === '') {
            returnValue = false;
        } else if (this.oaFields.postcode === '' && re.test(this.oaFields.huisnummer)) {
            returnValue = false;
        } else if (this.postcodeState && re.test(this.oaFields.huisnummer)) {
            this.readonlyStateValue = false;
            // this.getContactAddress();
            returnValue = true;
        } else {
            returnValue = false;
        }
        return returnValue;
    }

    get huisnummerTState() {
        if (this.postcodeState && this.huisnummerState) {
            this.readonlyStateValue = false;
            // this.getContactAddress();
        }
        return null;
    }

    private readonlyStateValue: boolean = true;
    get readonlyState() {
        return this.readonlyStateValue;
    }

    onReset(event: any) {
        event.preventDefault();
    }
    onSubmit(event: any) {
        event.preventDefault();
    }
    /**
     * resetForm()
     * resets all the form fields and when in wizardmode goto step 1
     */
    resetForm(event: any) {
        if (this.settings.wizard?.active === true && this.activeWizardPage === 3) {
            this.activeWizardPage = 1;
            this.wizardClassActive2 = false;
            this.wizardClassActive3 = false;
        } else {
            this.activeWizardPage = 0;
        }
        // Reset our form values
        this.calendarSelected = null;
        this.oaFields.preferenceSelected = 0;
        this.oaFields.preferenceOptions = [
            { value: 0, text: 'maak een keuze', selected: true, disabled: true },
            { value: 8, text: '08:00' },
            { value: 9, text: '09:00' },
            { value: 10, text: '10:00' },
            { value: 11, text: '11:00' },
            { value: 12, text: '12:00' },
            { value: 13, text: '13:00' },
            { value: 14, text: '14:00' },
            { value: 15, text: '15:00' },
            { value: 16, text: '16:00' },
            { value: 17, text: '17:00' }
        ];
        this.oaFields.workSelected = [];
        this.oaFields.overige = '';
        this.oaFields.priceSelected = [];
        this.oaFields.vervoerSelected = 1;
        this.oaFields.zakelijkSelected = 0;
        this.oaFields.kenteken = '';
        this.oaFields.tellerstand = '';
        this.oaFields.voornaam = '';
        this.oaFields.tussenvoegsel = '';
        this.oaFields.achternaam = '';
        this.oaFields.email = '';
        this.oaFields.telefoon = '';
        this.oaFields.postcode = '';
        this.oaFields.huisnummer = '';
        this.oaFields.huisnummertoevoeging = '';
        this.oaFields.adres = '';
        this.oaFields.plaats = '';
        this.oaFields.contact_id = '';
        this.oaFields.vehicle_id = '';
        this.vehicleData = '';
        // Trick to reset/clear native browser form validation state
        this.oaFields.show = false;
        this.$nextTick(() => {
            this.oaFields.show = true;
        });
    }

    submitModalHeader = 'Controleer het volgende';
    submitModalBody = '';
    // #### WIZARD STUFF
    /**
     * setWizard()
     * if wizard is active sets the navigation
     */
    private setWizard() {
        this.activeWizardPage = this.settings.wizard?.active === true ? 1 : 0;
        if (this.settings.wizard?.active === true) {
            switch (this.settings.wizard?.navigation) {
                case 'top':
                    this.wizardTop = true;
                    this.wizardBottom = false;
                    break;
                case 'bottom':
                    this.wizardTop = false;
                    this.wizardBottom = true;
                    break;
                case 'both':
                    this.wizardTop = true;
                    this.wizardBottom = true;
                    break;
                case 'none':
                    this.wizardTop = false;
                    this.wizardBottom = false;
                    break;
                default:
                    this.wizardTop = true;
                    this.wizardBottom = false;
                    break;
            }
        }
    }

    /**
     * nextWizardPage0()
     * navigate wizard page 0, button code to activate validation and submit
     * used when not in wizard mode
     */
    private async nextWizardPage0() {
        let canSubmitCalendar = await this.checkCalendarFields();
        if (canSubmitCalendar === true) {
            let canSubmitDetail = await this.checkDetailFields();
            if (canSubmitDetail === true) {
                let canSubmitContact = await this.checkContactFields();
                if (canSubmitContact === true) {
                    this.isSending = true;
                    this.preparePostAppointment();
                } else {
                    this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage0');
                }
            } else {
                this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage0');
            }
        } else {
            this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage0');
        }
    }

    private activeWizardPage: number = 0;
    private wizardClassActive1 = true;
    private wizardClassActive2 = false;
    private wizardClassActive3 = false;
    /**
     * nextWizardPage1()
     * navigate wizard page 1, button code
     */
    private async nextWizardPage1() {
        let canSubmit = await this.checkCalendarFields();
        if (canSubmit === true) {
            this.activeWizardPage += 1;
            this.wizardClassActive2 = true;
        } else {
            this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage1');
        }
    }
    /**
     * nextWizardPage2()
     * navigate wizard page 2, button code
     */

    private async nextWizardPage2() {
        let canSubmit = await this.checkDetailFields();
        if (canSubmit === true) {
            this.activeWizardPage += 1;
            this.wizardClassActive3 = true;
        } else {
            this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage2');
        }
    }
    /**
     * nextWizardPage3()
     * navigate wizard page 3, button code
     */
    private async nextWizardPage3() {
        let canSubmit = await this.checkContactFields();
        if (canSubmit === true) {
            this.isSending = true;
            this.preparePostAppointment();
        } else {
            this.$root.$emit('bv::show::modal', 'submitModal', '#btnNextPage3');
        }
    }

    /**
     * previousWizardPage()
     * navigate wizard page previous
     */
    private previousWizardPage() {
        this.activeWizardPage -= 1;
        this.activeWizardPage <= 2 ? (this.wizardClassActive2 = false) : true;
        this.activeWizardPage <= 3 ? (this.wizardClassActive3 = false) : true;
    }

    /**
     * checkCalendarFields()
     * validate fields step1
     * @return onError return modal with error
     */
    private async checkCalendarFields() {
        this.submitModalBody = '';
        if (this.calendarSelected === null) {
            this.scrollToTargetAdjusted('calendarId', 0);
            this.submitModalBody += 'Selecteer een datum';
            return false;
        } else if (this.oaFields.preferenceSelected === 0) {
            this.scrollToTargetAdjusted('preferenceSelectedId', 0);
            this.submitModalBody += 'Selecteer een voorkeurstijd';
            return false;
        } else {
            return true;
        }
    }

    /**
     * checkDetailFields()
     * validate fields step2
     * @return onError return modal with error
     */
    private async checkDetailFields() {
        this.submitModalBody = '';
        if (this.oaFields.workSelected.length === 0 && this.oaFields.overige === '') {
            this.scrollToTargetAdjusted('werkzaamhedenId', 0);
            this.submitModalBody += 'Kies minimaal 1 verrichting of voer iets in bij overige werkzaamheden.';
            return false;
        } else if (this.oaFields.vervoerSelected === 4 && this.oaFields.hasReplaceOptions && !this.oaFields.replaceSelected) {
            this.scrollToTargetAdjusted('vervangend', 0);
            this.submitModalBody += 'Selecteer een vervangend vervoer.';
            return false;
        } else if (this.oaFields.kenteken === '') {
            this.scrollToTargetAdjusted('kentekenId', 0);
            this.submitModalBody += 'Voer een kenteken in.';
            return false;
        } else {
            return true;
        }
    }

    /**
     * checkContactFields()
     * validate fields step3
     * @return onError return modal with error
     */
    private async checkContactFields() {
        this.submitModalBody = '';
        if (this.oaFields.voornaam === '') {
            this.scrollToTargetAdjusted('voornaamId', 0);
            this.submitModalBody += 'Voer uw voornaam in.';
            return false;
        } else if (this.oaFields.achternaam === '') {
            this.scrollToTargetAdjusted('achternaamId', 0);
            this.submitModalBody += 'Voer uw achternaam in.';
            return false;
        } else if (this.oaFields.email === '') {
            this.scrollToTargetAdjusted('emailId', 0);
            this.submitModalBody += 'Voer een email adres in.';
            return false;
        } else if (this.oaFields.telefoon === '') {
            this.scrollToTargetAdjusted('telefoonId', 0);
            this.submitModalBody += 'Voer een telefoonnummer in.';
            return false;
        } else if (this.oaFields.postcode === '') {
            this.scrollToTargetAdjusted('postcode', 0);
            this.submitModalBody += 'Voer een postcode in.';
            return false;
        } else if (this.oaFields.huisnummer === '') {
            this.scrollToTargetAdjusted('postcode', 0);
            this.submitModalBody += 'Voer een huisnummer in.';
            return false;
        } else if (!this.oaFields.huisnummer.match(/^[0-9]+$/)) {
            this.scrollToTargetAdjusted('postcode', 0);
            this.submitModalBody += 'Huisnummer mag alleen cijfers bevatten. Voor de toevoeging is een extra veld beschikbaar.';
            return false;
        } else {
            return true;
        }
    }

    /**
     * licensePlateFormatter()
     * set proper licenseplate format
     */

    licensePlateFormatter(value: string) {
        let properLicense = licensePlateService.setProperLicensePlate(value);
        return properLicense;
    }

    private vehicleData: string = '';
    private brand_type: string = '';

    /**
     * licensePlateBlur()
     * get RDW information based on licenseplate
     * on success set fields
     */
    async licensePlateBlur() {
        let RDWdata = await this.getRDW();
        if (RDWdata && this.rdwJson !== undefined) {
            let RDWdata = licensePlateService.getBasicData(this.rdwJson);
            let apkExpirationDate = DateTime.fromFormat(RDWdata.vervaldatum_apk, 'yyyyMMdd').toFormat('DDD');
            this.vehicleData = RDWdata.vehicleData + 'APK Vervaldatum: ' + apkExpirationDate;
            this.brand_type = RDWdata.brand_type;
        } else {
            this.vehicleData = 'Geen gegevens gevonden voor dit kenteken';
        }
        let vehicle = await this.getVehicle();
    }

    /**
     * postalcodeHouseNumberBlur()
     * get address based on postalcode and HouseNumber
     * on success set fields address and city
     * if an address is entered no changes will be made
     */
    async postalcodeHouseNumberBlur() {
        if (!this.oaFields.postcode || !this.oaFields.huisnummer || !this.oaFields.huisnummer.match(/^[0-9]+$/)) {
            return;
        }
        if (this.oaFields.adres && this.oaFields.plaats) {
            return;
        }
        let params: any = {
            country_id: '3FFDD7BC-976D-476A-A0EE-E333158A73DE', // Always NL
            postalcode: this.oaFields.postcode,
            house_number: this.oaFields.huisnummer
        };
        let url = `contact/checkaddress`;
        if (this.oaFields.huisnummertoevoeging) {
            params.house_number_add = this.oaFields.huisnummertoevoeging;
        }
        return this.ApiService.getApiData('', url, '', params).then(result => {
            if (result.status === 200) {
                let resultData: IAddress = result.data;
                this.oaFields.adres = resultData.address_found[0].address ? resultData.address_found[0].address : '';
                this.oaFields.plaats = resultData.address_found[0].city ? resultData.address_found[0].city : '';
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    /**
     * getSessionToken()
     * Check if supplied token exists on server
     * @returns true or false
     */
    async getSessionToken(): Promise<boolean> {
        return this.ApiService.getSessionToken().then(result => {
            if (result.status === 200) {
                let resultData: IAuthenticationComponent = result.data;
                this.$store.state.token = resultData.token;
                this.$store.state.token_valid_until = resultData.token_valid_until;
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    private text_step1: string = 'Kies een datum voor uw afspraak';
    private done_msg: string = 'Hartelijk dank voor je aanmelding, er is een email gezonden. indien nodig we nemen contact met je op';
    private footer_text: string = '';
    /**
     * getAppointmentConfig()
     * config file the OA defaults are overridden bij AppointmentConfig
     * @returns true or false
     */
    private async getAppointmentConfig(): Promise<boolean> {
        let params = { fields: 'config_id,text_step1,done_msg,email,minimal_available_for_appointment,medior_available_for_appointment,footer_text' };

        return this.ApiService.getApiData('', 'appointmentconfig', '', params).then(result => {
            if (result.status === 200) {
                let resultData: IAppointmentConfig = result.data.data[0];
                this.text_step1 = resultData.text_step1 ? resultData.text_step1 : this.text_step1;
                this.done_msg = resultData.done_msg ? resultData.done_msg : this.done_msg;
                this.footer_text = resultData.footer_text ? resultData.footer_text : this.footer_text;
                this.minimumAvailable = resultData.minimal_available_for_appointment ? resultData.minimal_available_for_appointment : this.minimumAvailable;
                this.mediorAvailable = resultData.medior_available_for_appointment ? resultData.medior_available_for_appointment : this.mediorAvailable;
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    /**
     * getScheduleAvailibility()
     * retrieve logged in user schedule availability
     * sets disabled and enabled dates and classes
     * @returns true or false
     */
    async getScheduleAvailibility(params: { year: number; month: number }) {
        return this.ApiService.getApiData('', 'util/schedule-availability', '', params).then(result => {
            if (result.status === 200) {
                let resultData: ISchedule[] = result.data.data;
                resultData.forEach(item => {
                    if (item.status) {
                        let newDate = item.date.split('-');
                        let realDate = new Date(Number(newDate[2]), Number(newDate[1]) - 1, Number(newDate[0]));
                        switch (item.status.toLowerCase()) {
                            case 'unavailable':
                                this.disabledDates.push(realDate);
                                break;
                            case 'blocked':
                                this.disabledDates.push(realDate);
                                break;
                            case 'closed':
                                this.disabledDates.push(realDate);
                                break;
                            case 'available':
                                this.enabledDates.push(realDate);
                                this.enabledHours.push(item.hour);
                                break;
                        }
                    }
                });
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    /**
     * getFlatrates()
     * retrieve logged in user flatrates, sets form field
     * @returns true or false
     */
    private async getFlatrates(): Promise<boolean> {
        let params: any = {
            fields: 'description,is_show_on_online_appointments,shortname',
            filter: 'is_show_on_online_appointments[eq]:1'
        };
        return this.ApiService.getApiData('', 'flatrate', '', params).then(result => {
            if (result.status === 200) {
                let resultData: IFlatrate[] = result.data.data;

                this.sortByKey(resultData, 'description');

                resultData.forEach(item => {
                    this.workOptions.push({
                        text: item.description,
                        value: item.flat_rate_id
                    });
                });
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    /**
     * getRentGroups()
     * retrieve logged in user rentgroup, sets form field
     * @returns true or false
     */
    private async getRentGroups(): Promise<boolean> {
        let params: any = {
            fields: 'description,is_show_on_online_appointments,code',
            filter: 'is_show_on_online_appointments[eq]:1'
        };
        return this.ApiService.getApiData('', 'rentgroup', '', params).then(result => {
            if (result.status === 200) {
                let resultData: IRentGroup[] = result.data.data;
                this.sortByKey(resultData, 'description');

                resultData.forEach(item => {
                    this.replaceOptions.push({
                        text: item.description,
                        value: item.rent_group_id
                    });
                });
                if (resultData.length > 0) {
                    this.oaFields.hasReplaceOptions = true;
                }
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    private rdwJson: IRdwVehicle;
    /**
     * getRDW()
     * get RDW information based on licenseplate
     * @returns true or false
     */
    private async getRDW(): Promise<Boolean> {
        return licensePlateService.getRDWData(this.oaFields.kenteken.replaceAll('-', '')).then(result => {
            if (result.status === 200) {
                this.rdwJson = result.data[0];
                return true;
            } else {
                this.isError = true;
                this.isFetching = false;
                this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
                return false;
            }
        });
    }

    /**
     * getVehicle()
     * retrieve vehicle based on licenseplate
     * sets vehicelId !@# TODO not used at the moment
     * @returns true or false
     */
    private async getVehicle() {
        let params: any = {
            fields: 'license_plate,vehicle_id',
            filter: 'license_plate[eq]:' + this.oaFields.kenteken.replaceAll('-', '')
        };
        const result: any = await this.ApiService.getApiData('', 'vehicle', '', params);
        if (result.status === 200) {
            let resultData: IVehicle = result.data.data;
            let resultDataCount = result.data.count;
            // send vehicle_id only if there is 1
            if (resultData.license_plate && resultDataCount.itemsVehicleCount === 1) {
                this.sendEmailBody.vehicle_id = resultData.vehicle_id;
            }
            return true;
        } else {
            this.isError = true;
            this.isFetching = false;
            this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
            return false;
        }
    }

    /**
     * getContactAddress()
     * get contact address for addres validation based on postalcode and houseno.
     * sets contactId !@# TODO not used at the moment
     * @returns true or false
     */
    private async getContactAddress() {
        let params: any = {
            country_id: '3FFDD7BC-976D-476A-A0EE-E333158A73DE', // Always NL
            postalcode: this.oaFields.postcode,
            house_number: this.oaFields.huisnummer,
            house_number_add: this.oaFields.huisnummertoevoeging
        };
        const result: any = await this.ApiService.getApiData('', 'contact/checkaddress', '', params);
        if (result.status === 200) {
            let resultData: IContactAddress = result.data.data;
            let resultDataCount = result.data.count;
            let resultDataClient: IContactClient = {} as IContactClient;
            if (result.data.address_found) {
                resultData = result.data.address_found;
            } else {
                resultData = { address: '', city: '', country_id: '' };
            }
            if (result.data.count > 0 || result.data.data.length > 0) {
                resultDataClient = result.data.data[0];
            }
            this.oaFields.adres = resultData.address;
            this.oaFields.plaats = resultData.city;
            this.readonlyStateValue = true;
            // only use if 1 client, to be sure
            if (resultDataCount === 1) {
                this.oaFields.contact_id = resultDataClient.contact_id;
            }
            if (resultDataCount === 0) {
                this.readonlyStateValue = false;
            }
            return true;
        } else {
            this.isError = true;
            this.isFetching = false;
            this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
            return false;
        }
    }

    /**
     * preparePostAppointment()
     * prepare Post Appointment data
     */
    //
    private async preparePostAppointment() {
        this.sendEmailBody.lic_plate = this.oaFields.kenteken;
        this.sendEmailBody.address = this.oaFields.adres;
        this.sendEmailBody.city = this.oaFields.plaats;
        this.sendEmailBody.telephone = this.oaFields.telefoon;
        this.sendEmailBody.h_nr = this.oaFields.huisnummer;
        this.sendEmailBody.nr_add = this.oaFields.huisnummertoevoeging;
        this.sendEmailBody.nm_ins = this.oaFields.tussenvoegsel;
        this.sendEmailBody.milometer = this.oaFields.tellerstand;
        this.sendEmailBody.post_code = this.oaFields.postcode;
        if (this.context.selectedDate) {
            this.sendEmailBody.app_date = DateTime.local(
                this.context.selectedDate.getFullYear(),
                this.context.selectedDate.getMonth() + 1,
                this.context.selectedDate.getDate(),
                this.oaFields.preferenceSelected
            ).toISO();
        }
        this.sendEmailBody.name = this.oaFields.achternaam;
        this.sendEmailBody.first_name = this.oaFields.voornaam;
        this.sendEmailBody.brand_type = this.brand_type;
        this.sendEmailBody.email = this.oaFields.email;
        this.sendEmailBody.veh_repl = Number(this.oaFields.vervoerSelected);
        this.sendEmailBody.rent_group_id = null;
        if (this.oaFields.replaceSelected) {
            this.sendEmailBody.rent_group_id = this.oaFields.replaceSelected;
        }
        this.sendEmailBody.vehicle_lease = Number(this.oaFields.zakelijkSelected);
        this.sendEmailBody.offer = this.oaFields.priceSelected.length > 0 ? 1 : 0;
        this.sendEmailBody.flatrate_ids = JSON.stringify(this.oaFields.workSelected);
        this.sendEmailBody.restwork = this.oaFields.overige;
        this.sendEmailBody.processed = 0;
        // not used because of planning - receptionist dialog in CLOUD
        // this.sendEmailBody.contact_id = this.oaFields.contact_id
        // this.sendEmailBody.vehicle_id = this.oaFields.vehicle_id

        let descr = '';

        if (this.sendEmailBody.restwork) {
            descr += this.sendEmailBody.restwork + '\n';
        }
        if (this.sendEmailBody.offer) {
            descr += 'Wil graag een prijsopgaaf ontvangen' + '\n';
        }
        if (this.sendEmailBody.vehicle_lease === 0) {
            descr += 'Ik rij mijn auto prive' + '\n';
        } else if (this.sendEmailBody.vehicle_lease === 1) {
            descr += 'Ik rij mijn auto zakelijk' + '\n';
        }

        this.sendEmailBody.descr = descr;

        if (this.settings.testMode?.active === true) {
            // do not post or email this form to client
            this.isSending = false;
            this.isSendingSuccess = true;
            this.sentEmailResponseData.emailData = JSON.stringify(this.sendEmailBody);
            this.sentEmailResponseData.email = this.oaFields.email;
        } else {
            this.postAppointment();
        }
    }

    /**
     * postAppointment()
     * post Appointment based on the form
     * set returnData
     * @returns true or false
     */
    private async postAppointment() {
        this.isSending = true;

        let params: any = {
            email: !this.settings.sendMail ? false : this.settings.sendMail
        };
        console.log('sendEmailBody', this.sendEmailBody);
        const result: any = await this.ApiService.postApiData('', 'appointment', '', params, this.sendEmailBody);
        if (result.status === 200) {
            this.$gtag.event('OA created', { component_token: this.settings.token });
            this.isSending = false;
            this.isSendingSuccess = true;
            if (result.data.data[0]) {
                this.sentEmailResponseData = result.data.data[0];
            } else {
                this.sentEmailResponseData.emailData = '<p>no data</p>';
            }
            return true;
        } else {
            this.isError = true;
            this.isSending = false;
            this.isSendingSuccess = false;
            this.APIerrorMessage = '(' + result.data.errorCode + ') ' + result.data.errorMessage;
            return false;
        }
    }

    sortByKey(array: Array<any>, key: string) {
        return array.sort((a, b) => {
            var x = a[key];
            var y = b[key];

            if (typeof x == 'string') {
                x = ('' + x).toLowerCase();
            }
            if (typeof y == 'string') {
                y = ('' + y).toLowerCase();
            }

            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    /**
     * scrollToTargetAdjusted()
     * scroll override, scroll to set id with offset
     */
    //
    private scrollToTargetAdjusted(id: string, amount: number) {
        const element = document.getElementById(id);
        const headerOffset = amount ? amount : 0;
        let elementPosition = element ? element.getBoundingClientRect().top : 0;
        let offsetPosition = window.innerHeight + elementPosition - headerOffset;

        window.scroll({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
}
